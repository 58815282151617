<template>
 <div id="ConfgDomains">
<Navigation/>
  <ConfgDomain />
  <Partners />
  <Footer />
 </div>
</template>

<script>

import Partners from "../../home/components/Partners.vue";
import Footer from "../../home/components/Footer.vue";
import ConfgDomain from "../components/ConfgDomain.vue";
import Navigation from "../../home/components/Navigation.vue";

export default {
 components: { Partners, Footer, ConfgDomain, Navigation },
};
</script>

<style lang="sass" scoped></style>
