<template>
 <div id="ConfgDomain">
  <b-container>
   <b-row>
    <b-col>
     <div class="header-title">
      <h4><i class="fa fa-globe"></i> Domains Configuration</h4>
     </div>
     <div class="aleart-after-header">
      <p>
       Please review your domain name selections and any addons that are
       available for them.
      </p>
     </div>
     <div class="body-title">
      <h4><i class="fa fa-globe"></i>abdo.com</h4>
     </div>
     <div class="body-check-addon">
      <b-container>
       <b-row>
        <b-col>
         <div class="Registration-Period">
          <h5>Registration Period</h5>
          <p>1 Year/s</p>
          <br />

          <h5>BackUp 50GB</h5>
          <p>1 Year/s</p>
         </div>
         <div>
          <header class="radio-option">
           <label>
            <div id="ins" class="radio-round">
             <input type="radio" />
             <ins v-on:click="showOption" class="iCheck-helper"></ins>
            </div>
            <span>ID Protection</span>
           </label>
           <p>4.05 USD / 1 Year/s</p>
          </header>
         </div>
        </b-col>
        <b-col>
         <div class="panel-custom">
          <div class="owns-add-ons">
           <h6>hosting</h6>
           <p>[ Has Hosting ]</p>
           <br />
          </div>
          <div class="owns-add-ons">
           <h6>SSL</h6>
           <p>[ Has SSL ]</p>
           <br />
          </div>
          <div class="owns-add-ons">
           <h6>cPanel</h6>
           <p>[ Has cPanel ]</p>
           <br />
          </div>
         </div>
        </b-col>
       </b-row>
      </b-container>
     </div>
     <div class="btnDomainContinue">
      <button type="submit">
       <router-link class="linkContinue" to="EditCartBeforeCheckout"
        >Continue</router-link
       >
       <i class="fa fa-arrow-circle-right"></i>
      </button>
     </div>
    </b-col>
   </b-row>
  </b-container>
 </div>
</template>

<script>
export default {
 name: "ConfgDomain",
 methods: {
  showOption: function () {
   var ins = document.getElementById("ins");
   if (ins.style.display == "none") {
    ins.style.background = "#FFF";
   } else {
    ins.style.background = "#06155a";
   }
  },
 },
};
</script>

<style lang="scss" scoped>
@import "@/styles/colors/_main-colors.scss";
@import "@/styles/fonts/_main-fonts.scss";
#ConfgDomain {
 padding: 150px 0;

 @mixin style-headers {
  padding: 10px 20px;
  background: map-get($branding, them-color-option-tow);
  border-radius: 2px;
 }
 @mixin style-text-header {
  color: blanchedalmond;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
 }
 @mixin style-icon-befor-header {
  margin-right: 5px;
  font-size: 19px;
 }
 @mixin style-list {
  font-size: 15px;
  border-bottom: 1px solid #f1ebeb;
  padding: 10px;
  margin-bottom: 0;
  color: #5b5858;
 }
 @mixin product-info {
  box-shadow: 0 0 13px 0 #00000042;
  margin-top: 10px;
  padding: 20px 15px;
  background-color: #fafafa;
  border-radius: 2px;
  background-color: #fff;
  border: 1px solid #efefef;
  margin: 8px 0 10px 0;
 }
 @mixin text-in-span-summary {
  font-size: 13px;
  color: #000;
  font-weight: 600;
 }
 .header-title {
  @include style-headers;
  h4 {
   @include style-text-header;
  }
  .fa-globe {
   @include style-icon-befor-header;
  }
 }
 .aleart-after-header {
  margin: 15px 0 15px 10px;
  font-size: 16px;
  color: map-get($color-texts, font-color-havef);
  font-family: $Paragraph-font;
 }
 .body-title {
  @include style-headers;
  h4 {
   @include style-text-header;
  }
  .fa-globe {
   @include style-icon-befor-header;
  }
 }
 .body-check-addon {
  @include product-info;
  .Registration-Period {
   padding: 20px 0;
   h5 {
    @include style-text-header;
    color: map-get($color-texts, font-list);
    font-weight: 500;
    margin: 5px 0;
   }
   p {
    font-family: $Paragraph-font;
    color: map-get($color-texts, font-color-havef);
   }
   .hr {
   }
  }
  .radio-option {
   label {
    align-items: center;
    font-size: 14px;
   }

   .img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
     width: 30%;
    }
   }
   p {
    color: #666;
    margin: 7px 0 3px 27px;
   }
   .radio-round {
    position: relative;
    background-position: -120px 0;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 3px 2px !important;
    width: 24px;
    height: 24px;
    background: #fff;
    border: 1px solid #4c4c4c;
    border-radius: 3px;
    cursor: pointer;
    border-radius: 11%;
    input {
     position: absolute;
     top: -20%;
     left: -20%;
     display: block;
     width: 140%;
     height: 140%;
     margin: 0px;
     padding: 0px;
     background: rgb(255, 255, 255);
     border: 0px;
     opacity: 0;
    }
    ins {
     position: absolute;
     top: -20%;
     left: -20%;
     display: block;
     width: 140%;
     height: 140%;
     margin: 0px;
     padding: 0px;
     background: rgb(255, 255, 255);
     border: 0px;
     opacity: 0;
    }
   }

   span {
    margin-left: 7px;
    color: map-get($branding, them-color-option-tow);
    font-weight: 900;
   }
   .radio-round::before {
    content: "\2713";
    height: 16px;
    width: 16px;
    color: #fff;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    line-height: 1;
    font-size: 21px;
   }
  }
 }
 .panel-custom {
  .owns-add-ons {
   h6 {
    @include style-text-header;
    color: map-get($color-texts, font-list);
    font-weight: 500;
    margin: 5px 0;
   }
   p {
    font-family: $Paragraph-font;
    color: map-get($branding, domain-checker-available);
    font-weight: 700;
    margin: 0;
    padding: 0;
   }
  }
 }
 .btnDomainContinue {
  margin: 35px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
   border: 1px solid map-get($branding, them-color-option-tow);
   box-shadow: 0 2px 4px -1px map-get($branding, them-color-option-tow);
   border-color: map-get($branding, them-color-option-tow);
   background-color: map-get($branding, them-color-option-tow);
   border-radius: 4px;
   padding: 9px 25px;
   .linkContinue {
    color: map-get($color-texts, main-color);
    font-family: $Numbers-font;
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
   }
   .fa-arrow-circle-right {
    color: map-get($color-texts, main-color);
    font-family: $Numbers-font;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 3px;
   }
  }
 }
}
</style>
